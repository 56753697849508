.sub {
  font-family: "BornReadySlanted-Regular";
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }
  line-height: 1.5;
  margin-bottom: 10px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  color: $primary;
  letter-spacing: 0;
}

h1 {
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }
  margin-bottom: 1.25rem;
}

h2 {
  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
  }
  margin-bottom: 1.25rem;
}

a:hover {
  text-decoration: underline;
}

.buttonlink {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 0;
    height: 2px;
    background: $primary;
    transition: width .3s ease;
  }

  &:hover:after,
  &:focus:after {
    width: 100%;
  }
}

.bg-primary {
  .buttonlink:after {
    background: $white;
  }
}
