html {
  height: -webkit-fill-available;
  //scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  //min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  line-height: 1.6;
}

main {
  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 96px);
  }
  @include media-breakpoint-down(md) {
    min-height: calc(100vh - 144px);
  }
}

.site-footer {
  background: rgb(255,89,0);
  background: -webkit-linear-gradient(left, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
  background: -o-linear-gradient(left, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
  background: linear-gradient(to right, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
  font-size: 1.25rem;
  padding: 2rem 0;

  .credit {
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  p, a {
    margin-bottom: 0;
    color: $white;
  }
}

button:focus {
  outline: none;
}

.container {
  @include media-breakpoint-down(sm) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.post-preview-image{
  width: 100%;
  background-size: cover !important;
  background-position: center !important;

  &:after {
    content: '';
    display: block;
    padding-bottom: 75%;
  }
}

#main-wrapper {
  padding: 5rem 0;

  &.pagebuilder {
    padding: 0;
  }
}

.wrapper {
  padding: 0;

  &#post-wrapper {}
  &#index-wrapper {}
  &#page-wrapper {}
  &#search-wrapper {}
  &#archive-wrapper {}
  &#shop-wrapper {}
}





// Blogposts
.article-thumbnail {
  width: 100%;
  background-size: cover !important;
  background-position: center !important;

  &:after {
    content: '';
    display: block;
    padding-bottom: 45%;
  }
}

.article-title {
  margin-top: 50px;
}

.article-date {
  color: $dark;
}

.article-content {
  margin-top: 30px;
  margin-bottom: 50px;
}

.comment-wrapper {
  background: #f9f9f9;
  padding: 30px;

  h3 {
    margin-bottom: 20px;
  }

  .blog-comment .blog-comment {
    padding: 30px;
    padding-right: 0;
  }

  .comment-form {
    label {
      font-weight: 700;
    }
  }
}





// Menu
.dropdown-menu {
  padding: 0;
}





.post-pagination {
  margin-top: 30px;
}

.widgettitle {
  font-size: 25px;
}

.sidebar-module {
  padding: 10px;
  margin: 0 -15px 5px;

  ul {
    padding-left: 0;
  }
}
.sidebar-module-inset {
  padding: 0px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.sidebar-module-inset p:last-child,
.sidebar-module-inset ul:last-child,
.sidebar-module-inset ol:last-child {
  margin-bottom: 0;
}

.custom-logo {
  max-height: 120px;
  width: auto;
}

.hamburger:focus {
  outline: 0;
}


img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }
