.posts {
  .post-preview {
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
  .card {
    height: 100%;

    .preview-thumbnail {
      width: 100%;
      background-size: cover !important;
      background-position: center !important;

      &:after {
        content: '';
        display: block;
        padding-bottom: 75%;
      }
    }
  }
}
