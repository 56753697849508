input.qty {
	height: 43px;
}

.price {
  color: $primary !important;
}

.product_meta {
  display: none;
}

.woocommerce-message::before,
.woocommerce-info::before {
  color: $primary;
}

.woocommerce-message,
.woocommerce-info {
  border-top-color: $primary;
}

.woocommerce a.remove {
  color: $primary !important;
}

.woocommerce a.remove:hover {
  background: $primary;
}
.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
  width: 200px;
}

.woocommerce-input-wrapper {
  width: 100%;
}

.woocommerce form .form-row .required {
  color: $primary;
}

@include media-breakpoint-down(md) {
	.woocommerce-ordering {
		float: none !important;
	}
}
