.call-to-action {

  @include media-breakpoint-down(md) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .banner {
    background: $secondary;
    padding: 6rem 3rem;
    position: relative;
  }

  .frame {
    position: absolute;
    top: 25px;
    bottom: 25px;
    left: 25px;
    right: 25px;
    border: 3px solid $primary;
    z-index: -1;
  }

  .pin {
    position: absolute;
    @include media-breakpoint-up(lg) {
      top: 60px;
      left: 60px;
      font-size: 2.5rem;
    }
    @include media-breakpoint-down(md) {
      top: -20px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 2rem;
    }
    background: $primary;
    border-radius: 50%;
    padding: 25px;
    font-family: "BornReadySlanted-Regular";
    color: $white;

    .inner {
      position: absolute;
      width: 90%;
      height: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 5px;
      border: 2px solid $white;
      border-radius: 50%;
    }
  }
}
