.contact-persons {

  .contact-person {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .cp-image {
      width: 100%;
      //border-radius: 50%;
      margin-bottom: 25px;
      background-size: cover !important;
      background-position: center !important;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }

    .cp-position {
      font-weight: 700;
    }

    .cp-mail {
      margin-bottom: .75rem;
    }

    .cp-phone {
      margin-bottom: .75rem;
    }

    .cp-mobile {
      margin-bottom: .75rem;
    }
  }
}
