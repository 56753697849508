$spacing-xs: 25px;
$spacing-s: 50px;
$spacing-m: 100px;
$spacing-l: 150px;
$spacing-xl: 200px;

.margin-top-xs {
  @include media-breakpoint-up(lg) {
    margin-top: $spacing-xs !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: $spacing-xs / 1.5 !important;
  }
}
.margin-bottom-xs {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xs !important;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-xs / 1.5 !important;
  }
}
.padding-top-xs {
  @include media-breakpoint-up(lg) {
    padding-top: $spacing-xs !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: $spacing-xs / 1.5 !important;
  }
}
.padding-bottom-xs {
  @include media-breakpoint-up(lg) {
    padding-bottom: $spacing-xs !important;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: $spacing-xs / 1.5 !important;
  }
}


.margin-top-s {
  @include media-breakpoint-up(lg) {
    margin-top: $spacing-s !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: $spacing-s / 1.5 !important;
  }
}
.margin-bottom-s {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-s !important;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-s / 1.5 !important;
  }
}
.padding-top-s {
  @include media-breakpoint-up(lg) {
    padding-top: $spacing-s !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: $spacing-s / 1.5 !important;
  }
}
.padding-bottom-s {
  @include media-breakpoint-up(lg) {
    padding-bottom: $spacing-s !important;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: $spacing-s / 1.5 !important;
  }
}


.margin-top-m {
  @include media-breakpoint-up(lg) {
    margin-top: $spacing-m !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: $spacing-m / 1.5 !important;
  }
}
.margin-bottom-m {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-m !important;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-m / 1.5 !important;
  }
}
.padding-top-m {
  @include media-breakpoint-up(lg) {
    padding-top: $spacing-m !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: $spacing-m / 1.5 !important;
  }
}
.padding-bottom-m {
  @include media-breakpoint-up(lg) {
    padding-bottom: $spacing-m !important;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: $spacing-m / 1.5 !important;
  }
}


.margin-top-l {
  @include media-breakpoint-up(lg) {
    margin-top: $spacing-l !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: $spacing-l / 1.5 !important;
  }
}
.margin-bottom-l {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-l !important;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-l / 1.5 !important;
  }
}
.padding-top-l {
  @include media-breakpoint-up(lg) {
    padding-top: $spacing-l !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: $spacing-l / 1.5 !important;
  }
}
.padding-bottom-l {
  @include media-breakpoint-up(lg) {
    padding-bottom: $spacing-l !important;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: $spacing-l / 1.5 !important;
  }
}


.margin-top-xl {
  @include media-breakpoint-up(lg) {
    margin-top: $spacing-xl !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: $spacing-xl / 1.5 !important;
  }
}
.margin-bottom-xl {
  @include media-breakpoint-up(lg) {
    margin-bottom: $spacing-xl !important;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: $spacing-xl / 1.5 !important;
  }
}
.padding-top-xl {
  @include media-breakpoint-up(lg) {
    padding-top: $spacing-xl !important;
  }
  @include media-breakpoint-down(md) {
    padding-top: $spacing-xl / 1.5 !important;
  }
}
.padding-bottom-xl {
  @include media-breakpoint-up(lg) {
    padding-bottom: $spacing-xl !important;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: $spacing-xl / 1.5 !important;
  }
}
