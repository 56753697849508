.background,
[class*='background-'] {
  position: relative;
}

[class*='background-'] {
  .background-image {
    opacity: .7;
  }
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  pointer-events: none;
}
