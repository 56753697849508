body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.9;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;

  .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 0;

    /* Image border */
    border: 7px solid white;
  }

  a img {
    border: none;
  }
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/layout/loading.gif) no-repeat;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;

  a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');

    &.lb-prev {
      width: 50%;
      left: 0;
      float: left;
      background: url(../img/layout/prev.png) left 48% no-repeat;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      -webkit-transition: opacity 0.6s;
      -moz-transition: opacity 0.6s;
      -o-transition: opacity 0.6s;
      transition: opacity 0.6s;

      &:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
      }
    }

    &.lb-next {
      width: 50%;
      right: 0;
      float: right;
      background: url(../img/layout/next.png) right 48% no-repeat;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      -webkit-transition: opacity 0.6s;
      -moz-transition: opacity 0.6s;
      -o-transition: opacity 0.6s;
      transition: opacity 0.6s;

      &:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
      }
    }
  }
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-dataContainer {
  margin: 0 auto;
  margin-top: 15px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-closeContainer {
  position: fixed;
  top: 30px;
  right: 30px;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;

  .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }

  .lb-caption {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1em;
    margin-top: 15px;

    a {
      color: #4ae;
    }
  }

  .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }

  .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    margin-right: -15px;
    background: url(../img/layout/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
    }
  }
}
