.teaserboxes {
  .teaserbox {
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
  .card {
    height: 100%;
    border: none;
    background: transparent;

    .cover {
      border-radius: 50%;
      padding: 20px;
      max-width: 100%;
    }
  }
}
