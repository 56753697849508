.hero {

  .hero-image {
    @include media-breakpoint-up(md) {
      height: calc(100vh - 98px);
    }
    @include media-breakpoint-down(sm) {
      height: 600px;
    }
    background: $secondary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;

    .logo {
      z-index: 1;
      @include media-breakpoint-up(md) {
        width: 400px;
      }
      @include media-breakpoint-down(sm) {
        width: 250px;
      }
    }

    .lead {
      font-size: 1.6rem;
      text-align: center;
      margin-top: 100px;
    }

    .scroll-down {
      position: absolute;
      bottom: 40px;

      a {
        text-decoration: none;
      }
    }

    .bounce {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }

    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-20px);
      }
      60% {
        transform: translateY(-10px);
      }
    }

    .flower-1,
    .flower-2,
    .flower-3 {
      position: absolute;
      user-select: none;
    }

    .flower-1 {
      width: 25vw;
      max-width: 250px;
      height: auto;
      left: 0;
    }

    .flower-2 {
      width: 50vw;
      max-width: 500px;
      height: auto;
      bottom: 0;
      right: 0;
    }

    .flower-3 {
      height: 25vw;
      max-height: 275px;
      width: auto;
      top: 0;
      right: 15%;
    }
  }

  .adressbar {
        background: rgb(255,89,0);
        background: -webkit-linear-gradient(left, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
        background: -o-linear-gradient(left, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
        background: linear-gradient(to right, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
        padding: 1.5rem 0;

        .contact-phone,
        .contact-mail {
          font-family: "Isidora-Bold";
          font-size: 1.5rem;
          text-decoration: none;
          color: $white;
          margin-bottom: 0;

          img {
            margin-right: 5px;
          }
        }

        .contact-mail {
          @include media-breakpoint-up(sm) {
            display: block;
          }
          @include media-breakpoint-down(xs) {
            display: none;
          }

          &.mobile {
            @include media-breakpoint-up(sm) {
              display: none;
            }
            @include media-breakpoint-down(xs) {
              display: block;
            }
          }
        }
      }
}
