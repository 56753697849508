.btn {
  font-family: "Isidora-Bold";
}

.social-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $secondary;
  position: relative;

  .border {
    background: $white;
    border: none !important;
    box-shadow: 0 0 25px rgba($primary, .1);
    transition: all ease .3s;

    &:hover {
      transform: scale(1.02);
    }
  }

  .sub {
    white-space: nowrap;
  }

  a {
    text-decoration: none !important;
    color: unset;
  }

  .flower-1,
  .flower-2,
  .flower-3 {
    position: absolute;
    user-select: none;
  }

  .flower-1 {
    width: 25vw;
    max-width: 250px;
    height: auto;
    left: 0;
  }

  .flower-2 {
    width: 50vw;
    max-width: 500px;
    height: auto;
    bottom: 0;
    right: 0;
  }

  .flower-3 {
    height: 25vw;
    max-height: 275px;
    width: auto;
    top: 0;
    right: 15%;
  }
}

.wrapper-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar-toggler {
  position: fixed;
  @include media-breakpoint-up(lg) {
    top: 25px;
    right: 25px;
  }
  @include media-breakpoint-down(md) {
    top: 10px;
    right: 10px;
  }
  z-index: 10001;
}

.hamburger-inner,
.hamburger-inner:before,
.hamburger-inner:after {
  background: $black;
}

.main-navigation {
  position: fixed;
  z-index: 1000;
  background: $primary;
  background: linear-gradient(45deg, rgba(255,89,0,1) 0%, rgba(243,124,0,1) 100%);
  overflow-y: scroll;

  top: 0;
  right: 0;
  height: 100vh;
  transition: all ease .5s;

  @include media-breakpoint-up(lg) {
    width: 50vw;
    margin-right: -50vw;
  }
  @include media-breakpoint-down(md) {
    width: 100vw;
    margin-right: -100vw;
  }

  &.toggled {
    margin-right: 0;
  }

  .menu {
    list-style-type: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin-left: 75px;

    @include media-breakpoint-down(md) {
      align-items: center;
      margin-left: 0;
    }

    .nav-link {
      position: relative;
      display: inline-block;
      font-size: 1.6rem;
      color: $white;
      padding: .75rem 0;

      &:after {
        content: '';
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 0;
        height: 3px;
        background: $white;
        transition: all ease .5s;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
}
