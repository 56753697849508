//background
.background-primary {
  background: $primary;
}
.background-secondary {
  background: $secondary;
}
.background-light {
  background: $light;
}
.background-dark {
  background: $dark;
}
.background-black {
  background: $black;
}


.bg-white { background: $white; }
.bg-black { background: $black; }

.btn-primary {
  &:hover {
    border-color: darken($primary, 10%);
  }
}

.bg-primary,
.background-primary {
  color: $white;

  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }

  .sub {
    color: $white;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $white;
    }
  }

  .btn {
    text-decoration: none;
  }

  .btn-primary {
    color: $primary;
    background: $white;
    border-color: $white;

    &:hover,
    &:focus {
      color: $primary;
      background: darken($white, 10%);
      border-color: darken($white, 10%);
    }
  }

  .btn-outline-primary {
    color: $white;
    border-color: $white;

    &:hover,
    &:focus {
      color: $primary;
      background: $white;
    }
  }

}



.bg-light,
.background-light {
  color: $black;

  h1, h2, h3, h4, h5, h6 {
    color: $primary;
  }

  .sub {
    color: $black;
  }

  a {
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  .btn-primary {
    color: $white;
    background: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      color: $white;
      background: darken($primary, 10%);
      border-color: darken($primary, 10%);
    }
  }

  .btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
  }

}



.bg-dark,
.background-dark {
  color: $white;

  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }

  .sub {
    color: $white;
  }

  a {
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  .btn-primary {
    color: $white;
    background: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      color: $white;
      background: darken($primary, 10%);
      border-color: darken($primary, 10%);
    }
  }

  .btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
  }

}



.bg-black,
.background-black {
  color: $white;

  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }

  .sub {
    color: $white;
  }

  a {
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  .btn-primary {
    color: $white;
    background: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      color: $white;
      background: darken($primary, 10%);
      border-color: darken($primary, 10%);
    }
  }

  .btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
  }

}
