img, figure {
  max-width: 100%;
  height: auto;
  margin-bottom: 0;
}

[class*='ratio-'] {
  position: relative;
  width: 100%;

  &:after {
    content: '';
    display: block;
  }
}

.ratio-1-1:after {
  padding-bottom: 100%;
}

.ratio-16-9:after {
  padding-bottom: 56.25%;
}

.ratio-4-3:after {
  padding-bottom: 75%;
}

.ratio-3-2:after {
  padding-bottom: 66.66%;
}

.cover {
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100% !important;
  width: 100% !important;
  user-select: none;
  pointer-events: none;
}
