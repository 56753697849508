.accordions {
  .card {
    margin-bottom: 20px;
    user-select: none;

    .card-header {
      position: relative;
      padding: 0;
      background: none;
      border-bottom: 0;

      &:hover {
        cursor: pointer;
      }

      h4 {
        margin-bottom: 0;
        padding: 1rem 1.25rem;
        font-size: 1rem;
      }

      i {
        font-size: 25px;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translate(0, -50%);
      }
    }

    .card-body {
      padding-top: 5px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
