.tiles {

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .tile-image {
    width: 100%;
    background-size: cover !important;
    background-position: center !important;

    &:after {
      content: '';
      display: block;
      padding-bottom: 75%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}
