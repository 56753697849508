.slick-next,
.slick-prev {
  transform: translate(0, -100%);

  &:before {
    font-family: unset !important;
    font-size: 2.5rem;
  }
}

.slick-slide:focus {
  outline: none !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

.imageslider {

  .slider-images {

    .slick-track {
      display: flex;
    }

    .slider-item {
      height: 500px;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-size: cover !important;
      background-position: center !important;
    }

    .slick-prev {
      left: 25px;
      z-index: 1;
    }

    .slick-next {
      right: 25px;
      z-index: 1;
    }

    .slick-dots {
      bottom: 20px;
    }
  }

  .container {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;

    .slider-item {
      @include media-breakpoint-up(lg) {
        height: 700px;
      }
      @include media-breakpoint-down(md) {
        height: 450px;
      }
    }
  }
}

.textslider {

  .slider-text {

    .slick-prev {
      left: 0;
      z-index: 1;
    }

    .slick-next {
      right: 0;
      z-index: 1;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }
}
